<template>
  <el-drawer
    visible
    :show-close="false"
    :modal="false"
    :wrapper-closable="false"
    :direction="$direction === 'rtl' ? 'ltr' : 'rtl'"
  >
    <template #title>
      <div class="d-flex justify-content-between text-typography-primary">
        <div>
          <h2>{{ $t('automation.templateManagement.structureParams.title') }}</h2>
          <p>{{ $t(`document.exports.schema.type.fullName.${template.documentType}`) }}</p>
        </div>
        <Button type="icon" class="p-0" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div class="h-100 d-flex flex-column justify-content-between">
      <StructureParamsConfig :form.sync="form" :document-type="template.documentType" :edit="edit" />
      <div class="d-flex justify-content-end p-4">
        <template v-if="edit">
          <Button type="secondary" @click="$emit('close')">{{ $t('commons.cancel') }}</Button>
          <Button @click="$emit('submit', form)">{{ $t('commons.apply') }}</Button>
        </template>
        <Button v-else @click="$emit('close')">{{ $t('commons.close') }}</Button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { clone } from 'ramda';
import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';
import { StructureParamsConfig } from '@/modules/document/components/structureParameters';

export default {
  components: {
    CloseIcon,
    StructureParamsConfig,
    Button,
  },
  props: {
    edit: { type: Boolean, default: true },
    template: { type: Object, required: true },
  },
  data() {
    return {
      form: clone(this.template.structureParams),
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep .structure-params-modal {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }
}
</style>
