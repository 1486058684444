<template>
  <el-dialog
    top="28vh"
    width="400px"
    visible
    append-to-body
    custom-class="rounded template-name-modal"
    :show-close="false"
    @close="$emit('close')"
  >
    <template #title>
      <div class="d-flex justify-content-between p-4">
        <div>
          <div class="d-flex align-items-center">
            <DeleteWarningIcon :size="24" color="#F38300" class="ms-2" />
            <h2>{{ $t('automation.templateManagement.replaceModal.title') }}</h2>
          </div>
        </div>
        <Button type="icon" class="p-0" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div class="row ps-4 pe-4">
      <div :class="$direction === 'rtl' ? 'pe-4' : 'ps-4'">
        <div>
          <p class="text-typography-primary no-word-break">
            {{ $t('automation.templateManagement.replaceModal.body', { templateName }) }}
          </p>
        </div>
        <div class="mt-5">
          <p class="text-typography-primary mb-1">
            {{ $t('automation.templateManagement.replaceModal.templateNameInput') }}
          </p>
          <el-form ref="replaceTemplateForm" :model="replaceTemplateForm" @submit.native.prevent="submitForm()">
            <el-form-item prop="name" :rules="{ required: true }">
              <el-input v-model="replaceTemplateForm.name" />
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="p-4">
        <Button type="secondary" class="modal-btn" @click="$emit('close')">{{
          $t('automation.templateManagement.replaceModal.cancel')
        }}</Button>
        <Button class="modal-btn warn-btn" @click="submitForm">{{
          $t('automation.templateManagement.replaceModal.approve')
        }}</Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';
import { CloseIcon, DeleteWarningIcon } from '@/assets/icons';

export default {
  components: { Button, CloseIcon, DeleteWarningIcon },
  props: {
    templateName: { type: String, default: '-' },
  },
  data() {
    return {
      replaceTemplateForm: { name: this.templateName },
    };
  },
  methods: {
    submitForm() {
      this.$refs.replaceTemplateForm.validate((valid) => {
        if (valid) {
          this.$emit('submit', {
            name: this.replaceTemplateForm.name,
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .template-name-modal {
  .el-dialog__headerbtn {
    top: 1em;
  }
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
  }

  .radio-column {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust the gap as needed */
  }
}

.no-word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}
.modal-btn {
  padding: 13px;
}

.warn-btn {
  font-weight: 400;
  background-color: #f38300;
}
</style>
