<template>
  <div class="h-100">
    <el-form ref="form" :model="formModel" :show-message="false">
      <!-- Field Type selector -->
      <div class="d-flex align-items-start">
        <h3 class="mb-5">{{ $t('automation.templateManagement.templateForm.form.title') }}</h3>
        <Button type="text" class="p-0 mx-2 text-typography-secondary delete-field-hover" @click="$emit('delete')">
          <TrashCanIcon width="20px" height="20px" />
        </Button>
      </div>
      <div class="mb-5">
        <p class="mb-1">{{ $t('automation.templateManagement.templateForm.form.fieldTypeLabel') }}</p>
        <div class="d-flex">
          <div :style="{ width: inputWidth }">
            <el-form-item prop="fieldTypes" required>
              <el-cascader
                v-model="formModel.fieldTypes"
                :options="getOptions"
                :props="{ expandTrigger: 'hover' }"
                @change="cleanFields"
              ></el-cascader
            ></el-form-item>
          </div>

          <!-- Table add column action -->
          <Button
            v-if="
              isTableForm &&
              (!tableColumns.length || (tableColumns.length && tableFieldTypeOptionsLeftToPick.length !== 0))
            "
            type="text"
            class="mx-2 p-0"
            @click="addTableColumn"
          >
            <div class="d-flex align-items-center">
              <PlusIcon />
              <p class="mx-1">{{ $t('automation.templateManagement.templateForm.form.addColumn') }}</p>
            </div>
          </Button>
        </div>
      </div>

      <!-- Table Type selector -->
      <template v-if="isTableForm">
        <div class="mb-5">
          <p class="mb-1">{{ $t('automation.templateManagement.templateForm.form.tableTypeLabel') }}</p>
          <span class="d-flex">
            <el-form-item prop="tableType">
              <el-radio-group v-model="formModel.tableType" size="medium" :disabled="isValueOnly">
                <el-radio
                  :label="TABLE_TYPES.standard"
                  :class="`table-type ${shouldShowTableTypeError ? 'error' : ''}`"
                >
                  {{ $t('automation.templateManagement.templateForm.form.tableTypes.standard') }}
                </el-radio>
                <el-radio
                  :label="TABLE_TYPES.nonStandard"
                  :class="`table-type ${shouldShowTableTypeError ? 'error' : ''}`"
                >
                  {{ $t('automation.templateManagement.templateForm.form.tableTypes.nonStandard') }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </span>
        </div>
      </template>

      <!-- Single type form -->
      <template v-if="!isTableForm && fieldType">
        <div class="mb-5">
          <p class="mb-1">{{ $t('automation.templateManagement.templateForm.form.keyValueDirection') }}</p>
          <div dir="ltr">
            <el-radio-group v-model="keyValueDirection" size="mini" class="key-value-direction" :disabled="isValueOnly">
              <el-radio-button :label="KEY_VALUE_DIRECTION.BTT">
                <OcrBottomToTopIcon :selected="keyValueDirection === KEY_VALUE_DIRECTION.BTT" />
              </el-radio-button>
              <el-radio-button :label="KEY_VALUE_DIRECTION.TTB">
                <OcrTopToBottomIcon :selected="keyValueDirection === KEY_VALUE_DIRECTION.TTB" />
              </el-radio-button>
              <el-radio-button :label="KEY_VALUE_DIRECTION.LTR">
                <OcrLeftToRightTopIcon :selected="keyValueDirection === KEY_VALUE_DIRECTION.LTR" />
              </el-radio-button>
              <el-radio-button :label="KEY_VALUE_DIRECTION.RTL">
                <OcrRightToLeftIcon :selected="keyValueDirection === KEY_VALUE_DIRECTION.RTL" />
              </el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div class="mb-5" :style="{ width: inputWidth }">
          <p class="mb-1">{{ $t('automation.templateManagement.templateForm.form.keyLabel') }}</p>
          <el-input v-model="keyLabel" :disabled="isValueOnly" />
          <Button type="text" :disabled="isValueOnly" @click="$emit('key-recognition')">
            {{ $t('automation.templateManagement.templateForm.form.automatedKeyRecognition') }}
          </Button>
        </div>
        <div class="d-flex mb-5">
          <el-checkbox v-model="isValueOnly" />
          <p class="mx-2">{{ $t('automation.templateManagement.templateForm.form.isValueOnly') }}</p>
        </div>
      </template>

      <!-- Table type form -->
      <template v-else>
        <div class="d-flex flex-wrap mb-5">
          <div
            v-for="(column, index) in ltrColumns"
            :key="index"
            :style="{ width: '120px' }"
            :class="{ ['m-2']: index !== 0 || tableColumns.length - 1 !== index }"
          >
            <div class="d-flex justify-content-center mb-1">
              <Button type="text" class="text-typography-secondary p-0 delete-hover" @click="deleteTableColumn(index)">
                <DeleteIcon />
              </Button>
            </div>
            <el-input v-model="column.label" class="mb-1" />
            <el-cascader
              v-model="column.key"
              :options="getTableOptions"
              :props="{ expandTrigger: 'hover' }"
              clearable
            ></el-cascader>
          </div>
        </div>
      </template>

      <!-- Submit actions -->
      <div>
        <Button type="secondary" @click="$emit('cancel')">{{ $t('commons.cancel') }}</Button>
        <Button @click="submit">{{ $t('commons.apply') }}</Button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { Button } from '@/modules/core';

import { PlusIcon, DeleteIcon, TrashCanIcon } from '@/assets/icons';
import { OcrBottomToTopIcon, OcrTopToBottomIcon, OcrRightToLeftIcon, OcrLeftToRightTopIcon } from '../icons';
import { KEY_VALUE_DIRECTION, FIELD_TYPE } from '../tools/constants';

const createEmptyFormField = () => ({ key: null, label: null });

const TABLE_TYPES = {
  standard: 'standard',
  nonStandard: 'nonStandard',
};

const FIELDS = [
  { key: 'issueDate', type: FIELD_TYPE.DATE },
  { key: 'documentNumber', type: FIELD_TYPE.STRING },
  { key: 'allocationNumber', type: FIELD_TYPE.STRING },
  { key: 'deliveryDate', type: FIELD_TYPE.DATE },
  { key: 'orderReference', type: FIELD_TYPE.STRING },
  {
    key: 'items',
    type: FIELD_TYPE.ARRAY,
    subFields: [
      { key: 'index', type: FIELD_TYPE.STRING },
      { key: 'name', type: FIELD_TYPE.STRING },
      { key: 'sku', type: FIELD_TYPE.STRING },
      { key: 'gtin', type: FIELD_TYPE.STRING },
      { key: 'quantity', type: FIELD_TYPE.NUMBER },
      { key: 'packageQuantity', type: FIELD_TYPE.NUMBER },
      { key: 'quantityInPackage', type: FIELD_TYPE.NUMBER },
      { key: 'price', type: FIELD_TYPE.MONEY },
      { key: 'netPrice', type: FIELD_TYPE.MONEY },
      { key: 'discountRate', type: FIELD_TYPE.NUMBER },
      { key: 'discountAmount', type: FIELD_TYPE.MONEY },
      { key: 'totalDiscount', type: FIELD_TYPE.MONEY },
      { key: 'totalTax', type: FIELD_TYPE.MONEY },
      { key: 'totalDeposits', type: FIELD_TYPE.MONEY },
      { key: 'totalPackages', type: FIELD_TYPE.MONEY },
      { key: 'totalAmount', type: FIELD_TYPE.MONEY },
      { key: 'reference', type: FIELD_TYPE.STRING },
    ],
  },
  {
    key: 'references',
    type: FIELD_TYPE.ARRAY,
    subFields: [
      { key: 'documentNumber', type: FIELD_TYPE.STRING },
      { key: 'netAmount', type: FIELD_TYPE.MONEY },
      { key: 'totalAmount', type: FIELD_TYPE.MONEY },
      { key: 'issueDate', type: FIELD_TYPE.DATE },
      { key: 'debitAmount', type: FIELD_TYPE.MONEY },
      { key: 'creditAmount', type: FIELD_TYPE.MONEY },
      { key: 'balance', type: FIELD_TYPE.MONEY },
      { key: 'paymentDueDate', type: FIELD_TYPE.DATE },
    ],
  },
  {
    key: 'generalCharges',
    type: FIELD_TYPE.ARRAY,
    subFields: [
      { key: 'name', type: FIELD_TYPE.STRING },
      { key: 'amount', type: FIELD_TYPE.MONEY },
    ],
  },
  { key: 'discountRate', type: FIELD_TYPE.NUMBER },
  { key: 'discountAmount', type: FIELD_TYPE.MONEY },
  { key: 'rounding', type: FIELD_TYPE.MONEY },
  { key: 'netAmount', type: FIELD_TYPE.MONEY },
  { key: 'taxableAmount', type: FIELD_TYPE.MONEY },
  { key: 'nonTaxableAmount', type: FIELD_TYPE.MONEY },
  { key: 'taxRate', type: FIELD_TYPE.NUMBER },
  { key: 'taxAmount', type: FIELD_TYPE.MONEY },
  { key: 'totalAmount', type: FIELD_TYPE.MONEY },
  { key: 'paymentDueDate', type: FIELD_TYPE.DATE },
  { key: 'paidAmount', type: FIELD_TYPE.MONEY },
  { key: 'paymentDate', type: FIELD_TYPE.DATE },
  { key: 'paymentMethod', type: FIELD_TYPE.OBJECT },
  { key: 'openingBalance', type: FIELD_TYPE.MONEY },
  { key: 'amountDue', type: FIELD_TYPE.MONEY },
  { key: 'referencesFromDate', type: FIELD_TYPE.DATE },
  { key: 'referencesToDate', type: FIELD_TYPE.DATE },
];

const otherField = {
  key: 'other',
  children: [
    { key: 'otherNumber', type: FIELD_TYPE.NUMBER },
    { key: 'otherMoney', type: FIELD_TYPE.MONEY },
    { key: 'otherDate', type: FIELD_TYPE.DATE },
    { key: 'otherString', type: FIELD_TYPE.STRING },
  ],
};

export default {
  components: {
    Button,
    OcrBottomToTopIcon,
    OcrTopToBottomIcon,
    OcrRightToLeftIcon,
    OcrLeftToRightTopIcon,
    PlusIcon,
    DeleteIcon,
    TrashCanIcon,
  },
  props: {
    template: { type: Object, default: null },
    form: { type: Object, default: null },
  },
  data() {
    return {
      TABLE_TYPES,
      KEY_VALUE_DIRECTION,
      keyValueDirection: null,
      isValueOnly: false,
      keyLabel: null,
      tableColumns: null,
      inputWidth: '180px',
      formModel: {
        fieldTypes: null,
        tableType: null,
      },
      clickedSubmitted: false,
    };
  },
  computed: {
    shouldShowTableTypeError() {
      return this.clickedSubmitted && !this.isTableTypeValid;
    },
    isTableTypeValid() {
      return !this.isTableForm || Object.values(this.TABLE_TYPES).includes(this.formModel.tableType);
    },
    fieldType() {
      return this.formModel.fieldTypes[this.formModel.fieldTypes.length - 1];
    },
    structureParams() {
      return this.template?.structureParams;
    },
    field() {
      const field = FIELDS.find(({ key }) => key === this.fieldType);
      const fieldOther = otherField.children.find(({ key }) => key === this.fieldType);
      return field ?? fieldOther;
    },
    isTableForm() {
      return this.field?.type === FIELD_TYPE.ARRAY;
    },
    fieldTypeOptionsLeftToPick() {
      return this.fieldTypeOptions.filter(
        ({ value: key }) => key !== this.fieldType && !this.template.fields.some((field) => field.key === key)
      );
    },
    fieldTypeOptions() {
      return [...FIELDS, otherField]
        .filter(({ key }) => ['other'].includes(key) || Boolean(this.structureParams[key]))
        .map(({ key: fieldType, children }) => ({
          label: this.$t(`document.exports.schema.fields.${fieldType}`),
          value: fieldType,
          children: children?.map(({ key: fieldType }) => ({
            label: this.$t(`document.exports.schema.fields.${fieldType}`),
            value: fieldType,
          })),
        }));
    },
    tableFieldTypeOptionsLeftToPick() {
      return this.tableFieldTypeOptions.filter(({ value: key }) => !this.tableColumns.some((col) => col.key === key));
    },
    tableFieldTypeOptions() {
      return (this.field?.subFields ? [...this.field?.subFields, otherField] : [])
        .filter(({ key }) => key === 'index' || key === 'other' || Boolean(this.structureParams[this.fieldType][key]))
        .map(({ key, children }) => ({
          label: this.$t(
            key === 'index'
              ? 'automation.templateManagement.templateForm.form.fieldIndex'
              : `document.exports.schema.${this.fieldType}Fields.${key}`
          ),
          value: key,
          children: children?.map(({ key }) => ({
            label: this.$t(`document.exports.schema.fields.${key}`),
            value: key,
          })),
        }));
    },
    ltrColumns() {
      return this.$direction === 'rtl' ? [...(this.tableColumns ?? [])].reverse() : this.tableColumns;
    },
    getOptions() {
      const options = [...this.fieldTypeOptionsLeftToPick];
      const foundOption = this.fieldTypeOptions.find(({ value }) => value === this.fieldType);
      if (foundOption) options.unshift(foundOption);
      return options;
    },
    getTableOptions() {
      const options = [...this.tableFieldTypeOptionsLeftToPick];
      const foundOption = this.tableFieldTypeOptions.find(({ value }) => value === this.fieldType);
      if (foundOption) options.unshift(foundOption);
      return options;
    },
  },
  watch: {
    isValueOnly() {
      this.keyValueDirection = null;
      this.keyLabel = null;
    },
    form: {
      handler() {
        // initialize the data from the props.form:

        this.formModel.fieldTypes = this.getFormFieldTypes(this.form);
        this.formModel.tableType = this.form?.tableType;
        this.keyLabel = this.form?.label;
        this.keyValueDirection = this.form?.keyValueDirection;
        this.tableColumns = this.form?.fields?.map((field) => ({ ...field, key: this.getFormFieldTypes(field) }));
        this.clickedSubmitted = false;
      },
      immediate: true,
    },
  },
  methods: {
    getFormFieldTypes(field) {
      return field?.key ? [field?.key] : field?.valueType ? ['other', 'other' + field?.valueType] : [];
    },
    async submit() {
      const valid = this.isTableTypeValid && (await this.$refs.form.validate().catch((err) => err));
      this.clickedSubmitted = true;
      if (!valid) return;

      const data = {
        ...this.form,
        key: this.fieldType.startsWith('other') ? undefined : this.fieldType,
        label: this.keyLabel,
        valueType: this.field?.type,
        keyValueDirection: this.keyValueDirection,
      };
      if (this.isTableForm) {
        data.tableType = this.formModel.tableType;
        data.fields = this.tableColumns.map((col) => {
          const columnKey = col.key[col.key.length - 1];
          return {
            ...col,
            key: columnKey?.startsWith('other') ? undefined : columnKey,
            valueType: [...this.field?.subFields, ...otherField.children].find(({ key }) => key === columnKey)?.type,
          };
        });
      }
      this.$emit('submit', data);
    },
    addTableColumn() {
      this.tableColumns = [...this.tableColumns, createEmptyFormField()];
    },
    deleteTableColumn(index) {
      const updatedTableColumns = [...this.tableColumns];
      const indexToRemove = this.$direction === 'rtl' ? this.tableColumns.length - 1 - index : index;
      updatedTableColumns.splice(indexToRemove, 1);
      this.tableColumns = updatedTableColumns;
    },
    cleanFields() {
      this.tableColumns = this.form?.fields?.map((field) => ({ ...field, key: this.getFormFieldTypes(field) })) ?? [
        createEmptyFormField(),
      ];
      this.keyLabel = null;
      this.keyValueDirection = null;
      this.formModel.tableType = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.delete-hover:hover {
  color: $error;
}
.delete-field-hover:hover {
  color: $primary;
}

::v-deep .key-value-direction {
  .el-radio-button__inner {
    background: $secondary;
  }
  .is-active {
    .el-radio-button__inner {
      background: $primary;
    }
  }
}

::v-deep .table-type.error {
  .el-radio__inner {
    border-color: $error;
  }
  .el-input__inner {
    border-color: $error;
  }
}
</style>
